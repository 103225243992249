.je-logo{
    width:55px !important;
    height:44px !important;
}

.width-50px{
    width:50px;
}

.w-80{
    width:80% !important;
}

.w-90{
    width:90% !important;
}

.w-100-fixed{
  width:100px;
}

.pl-0-important{
    padding-left: 0px !important;
}

.zindex-10{
    z-index: 10 !important;
}

.default-background-color{
    background-color: #fafbfc !important;
}

.default-background-color:hover{
    background-color: #fafbfc !important;
}

.grayout {
    opacity: 0.25; /* Real browsers */
    filter: alpha(opacity = 25); /* MSIE */
}

.color-success{
    color:#20a800 !important;
}

.je-badge-danger{
    padding: 0.7em 0.7em  !important;
    font-size: 100%  !important;
    font-weight:bold  !important;
    text-transform:none !important;
    border-color: #c74b4b !important;
    border-radius:5px !important;
    height:40px !important;
    vertical-align: middle !important;
}

.je-badge-primary{
    padding: 0.7em 0.7em  !important;
    font-size: 100%  !important;
    font-weight:bold  !important;
    text-transform:none !important;
    border-radius:5px !important;
    height:40px !important;
    vertical-align: middle !important;
}

.transform-30{
    -ms-transform: rotate(-30deg); /* IE 9 */
    transform: rotate(-30deg);
}

label {
    font-weight: bold !important;
  
}

.disable-css-transitions {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.list-no-bullets{
    list-style-type:none;
}

.candidate-page-title{
    margin: -2rem -2rem 1rem;
    padding: 2rem;
    transition: all 0.2s ease-in-out;
    background:rgb(249, 246, 246,0.8) !important;
    padding-bottom: 0;
}

/* Sidebar menu */
.sidebar-navigation ul li > a {
 color:#454545 !important;
}
.sidebar-navigation ul li > a:hover {
    color:#454545 !important;
}
.sidebar-navigation ul li.active {
    color:#454545 !important;
    font-weight: bold !important;
}
/* End of side meny*/    

/* */
.app-page-title--description {
    opacity:1 !important;
}
/* */

/*This is for ad area*/
.ad-btn-live{
    border-radius: 5px;
    border: solid 1px #138900 !important;
    color: #138900 !important;
}

.ad-btn-live:hover, .ad-btn-live:focus, .ad-btn-live:active{
    border-radius: 5px;
    border: solid 1px #138900 !important;
    color: #138900 !important;
}
/*End for ad area*/

.btn-neutral-secondary{
    color:#fff !important;
    background-color: #727373 !important;
}


.btn-neutral-secondary:hover{
    color:#fff !important;
    background-color: #010101 !important;
}


.btn-default-je{
    border-radius: 5px;
    border: solid 1px #04365e !important;
    color: #04365e !important;
}

.btn-live{
    border-radius: 5px;
    border: solid 1px #80ff00 !important;
    background-color: rgba(49, 255, 0, 0.06);
}

.btn-expired{
    border-radius: 5px;
    border: solid 1px #d9e2ea !important;
    background-color:#d9e2ea;
}

.btn-processing{
    border-radius: 5px;
    border: solid 1px #f2f2f2 !important;
    background-color:#f2f2f2;
}

.btn-live:hover, .btn-live:focus, .btn-live:active{
    border-radius: 5px;
    border: solid 1px #80ff00 !important;
    background-color: rgba(49, 255, 0, 0.06);
    color: #20a800 !important;
}

.bg-transparent{
    background-color: transparent !important; 
}

.bg-white{
   background-color: #fff !important; 
}

.text-white{
    color: #fff !important;
}

.text-black{
    color: #000 !important;
}

.text-default{
    color: #04365e !important;
}

.text-live{
    color: #138900 !important;
}

.text-live:hover{
    color: #138900 !important;
}


.text-underline{
    text-decoration:underline;
}

.btn-pending-approval{
    border-radius: 5px;
    border: solid 1px #9b5ba4 !important;
    box-shadow: 0 2px 2px 0 rgba(191, 210, 222, 0.5);
    background-color: #9b5ba4;
}

.btn-pending-approval:hover, .btn-pending-approval:focus, .btn-pending-approval:active{
    border-radius: 5px;
    border: solid 1px #9b5ba4 !important;
    background-color: #9b5ba4;
    color: #fff !important;
}

.text-pending-approval{
    color: #fff !important;
}

.badge-ad-pending-approval{
    border-radius: 5px;
    color: #fff;
    background-color: #9b5ba4;
    border: solid 1px #9b5ba4 !important;
    text-align: center;
}

.badge-ad-published{
    border-radius: 5px;
    border: solid 1px #138900 !important;
    background-color:#138900;
    color: #fff !important;
    text-align: center;
}

.badge-ad-processing{
    background-color:#2349d3;
    border-radius: 5px;
    border: solid 1px #2349d3 !important;
    color: #FFF !important;
}

.badge-review-by-je{
    background-color:#827400;
    border-radius: 5px;
    /*
    border: solid 1px #d9e2ea !important;
    color: #75899b !important;
    */
    border: solid 1px #827400 !important;
    color: #FFF !important;
    
}

.badge-ad-expired{
    border-radius: 5px;
    color: #fff;
    background-color: #636363;
    border: solid 1px #636363 !important;
    text-align: center;
    
}

.badge-ad-canceled{
    border-radius: 5px;
    color: #fff;
    background-color: #ab1616;
    border: solid 1px #ab1616 !important;
    text-align: center;
    
}

.ad-processing{
    border-radius: 5px;
    border: solid 1px #d9e2ea !important;
    color: #75899b !important;
}

.ad-processing:hover, .ad-processing:focus, .ad-processing:active{
    border-radius: 5px;
    border: solid 1px #d9e2ea !important;
    color: #75899b !important;
}

.ad-published{
    border-radius: 5px;
    border: solid 1px #80ff00 !important;
    background-color: rgba(49, 255, 0, 0.06);
    color: #20a800 !important;
}


.je-header-page-title{
    padding-top: 1rem;
    padding-left: 1rem;
}


.je-header-dashboard-page-title{
    /*width: 155px;*/
    height: 40px;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
}

.je-header-campaign-detail-page-title{
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
}

.je-header-text-display-area{
    margin-bottom: 0.5rem;
}

.je-header-main-header{
    font-size: 28px;
    font-weight: bold;
    line-height: 1.2;
}
.je-header-small-subheader{
    font-size: 0.95rem;
    font-weight: 500;
}

.dashboard-status-display-pending-approval{
    width: 18.6px;
    height: 8.3px;
    border-radius: 8px;
    background-color: #9b5ba4;
}

.dashboard-status-display-review-by-je{
    width: 18.6px;
    height: 8.3px;
    border-radius: 8px;
    /*background-color: #76899A;*/
    background-color: #827400;
}

.dashboard-status-display-published{
    width: 18.6px;
    height: 8.3px;
    border-radius: 8px;
    border: solid 1px #138900;
    background-color: #138900;
}

.dashboard-status-display-expired{
    width: 18.6px;
    height: 8.3px;
    border-radius: 8px;
    background-color: #636363;
}

.dashboard-status-display-processing{
    width: 18.6px;
    height: 8.3px;
    border-radius: 8px;
    border: solid 1px #2349d3;
    background-color: #2349d3;
}

.dashboard-location-pin{
    width: 11.1px;
    height: 15.9px;
    object-fit: contain;
}

.dashboard-campaign-item-detail{
    width: 36.7px;
    height: 120.5px;
    
}

.dashboard-campaign-item-detail-pie{
    width: 36.7px;
    height: 120.5px;
}

.dashboard-item-detail-highlight{
    background-color: #f5f6fa;
}

.dashboard-item-detail-non-highlight{
    background-color: rgba(255, 255, 255, 0.19);
}

.dashboard-campaign-item-ad-list li{
    padding-top:5px;
    
}

.dashboard-campaign-item-li-pending-approval{
    width: 17.7px;
    height: 17.7px;
    border-radius: 8px;
    background-color: #9b5ba4;
}

.dashboard-campaign-item-li-review-by-je{
    width: 17.7px;
    height: 17.7px;
    border-radius: 8px;
    background-color: #76899A;
}

.dashboard-campaign-item-li-published{
    width: 17.7px;
    height: 17.7px;
    border-radius: 8px;
    border: solid 1px #80ff00;
    background-color: #f2ffef;
}

.dashboard-campaign-item-li-expired{
    width: 17.7px;
    height: 17.7px;
    border-radius: 8px;
    background-color: #d9e2ea;
}

.dashboard-campaign-item-li-processing{
    width: 17.7px;
    height: 17.7px;
    border-radius: 8px;
    border: solid 1px #bfbfbf;
}

.dashboard-unpaid-invoice-area{
    padding-left:2rem;
    padding-right:1rem;
}

.cursor-pointer{
    cursor:pointer;
}

.cursor-default{
    cursor:default;
}

.cursor-not-allowed{
    cursor:not-allowed !important;
}

.table th, .table td {
    border-top: 0px solid #dcdef1 !important;
    border-bottom: 1px solid #dcdef1;
}


/*Request a quote*/
.background-transparent{
    background-color:#fff0;
}

.background-white{
    background-color:#fff !important;
}

.background-image-request-quote{
    background-image: url("../images/request-a-quote-background.png");
    background-repeat: no-repeat;
    background-size: 523px 763px;
    
}

.recommended-title {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #98d18a;
    margin-bottom:0px;
}

.classic-style-title {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #7a7b97;
    margin-bottom:0px;
  }

.step2-job-is-remote{
    color:#9b5ba4;
    font-size: 16px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: right;
}

.step2-job-remote-checkbox{
    border-radius: 6px;
    border: solid 2px #9b5ba4;
    background-color: #fff;
}

.header-info-icon-font{
    font-size:0.95rem !important;
}

.step5-editor-tip{
    font-family: BasicSans-It;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color:#77009f;

}

.text-tertiary{
    color:#9b5ba4 !important;
}

.calendar-1 {
    width: 32.9px;
    height: 35.3px;
    object-fit: contain;
}

.generic-review-component-step-title{
    text-transform:uppercase;
    color:#04365e;

}

.dashboard-icon{
    font-size: 2.1rem !important;
    font-weight:600;
}
/*End of Request a quote*/


/* Invoice view */
.je-badge-invoice-open{
    color: rgba(217, 113, 113, 1) !important;
    padding: 0.7em 0.7em  !important;
    font-size: 26px  !important;
    font-weight:300  !important;
    text-transform:none !important;
    border-color: rgba(217, 113, 113, 1) !important;
    border-radius:15px !important;
    height:60px !important;
    width:300px !important;
    vertical-align: middle !important;
}

.je-badge-invoice-closed{
    color: #20a800 !important;
    padding: 0.7em 0.7em  !important;
    font-size: 26px  !important;
    font-weight:300  !important;
    text-transform:none !important;
    border-color: #20a800 !important;
    border-radius:15px !important;
    height:60px !important;
    width:250px !important;
    vertical-align: middle !important;
}

/*End of Invoice view*/

.notification-bubble {
    left:28px !important;
    top:10px !important;
}

.alert-notification-ad-review {
    color: #9b5ba4;
    background-color: #fbe8ff;
    border-color: #fbe8ff;
}

.alert-notification-link-color{
    color: #743f47;
}

.alert-notification-link-color:hover{
    color: #743f47;
}

.rc-pagination-prev{
    display:block !important;
}

/* Datepicker */

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #454545 !important;
    
}

.react-datepicker__day:hover {
    color: #fff !important;
    
}

.react-datepicker__day-name{
    font-weight: bold !important;
}

.react-datepicker__day--disabled {
    color: #8b8989 !important;
}
.react-datepicker__day--disabled:hover {
    color: #8b8989 !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #138900 !important;
    color: #fff !important;
    font-weight:bold;
}
.react-datepicker__day--keyboard-selected:hover {
    color: #fff !important;
    font-weight:bold;
}
.react-datepicker__day--selected{
    color: #fff !important;
    font-weight:bold;
}


/* End of datepicker */

/* Typeahead dropdown*/

.css-1wa3eu0-placeholder{
    color: #454545 !important;
}

/* end of Typeahead dropdown*/


/* sweet alert */
.swal2-icon.swal2-error {
    border-color: #ab1616 !important;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    background-color: #ab1616 !important;
}

.swal2-styled{
    background-color: #04365e !important;
}

/* End of sweet alerts */


.publication-image {
    display: block;
    max-width: 100%;
    height: auto;
}

.nav-dropdown-icon-image{
    width:40px;
    height: 40px;
}

/* Alerts */
.alert-danger {
    color: #ab1616 !important;
    background-color: #fff5f6 !important;
    border-color: #c74b4b !important;
}
/* End of alert */