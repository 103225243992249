  h2 {
    font-size: 1rem;
  }

  p {
    font-size:0.95rem;
  }
  
  @include media-breakpoint-up(sm) {
    h2 {
      font-size: 1.4rem;
    }

    p {
      font-size:0.95rem;
    }
  }
  
  @include media-breakpoint-up(md) {
    h2 {
      font-size: 1.6rem;
    }

    p {
      font-size:0.95rem;
    }
  }
  
  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 1.9rem;
    }

    p {
      font-size:0.95rem;
    }
  }