//  Core

.badge {
  &.badge-circle-inner,
  &.badge-circle {
    text-indent: -999rem;
    @include border-radius(100%);
    height: 10px;
    width: 10px;
    padding: 0;
    box-shadow: 0 0 0 2px $white;
  }

  &.badge-circle-inner {
    width: 16px;
    height: 16px;
    position: relative;

    &:after {
      content: '';
      background: $white;
      width: 8px;
      height: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -4px;
      @include border-radius(100%);
    }
  }

}
