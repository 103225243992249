// Sidebar menu dark color scheme

.app-sidebar {
  &--dark {
    .sidebar-navigation {

      .sidebar-header {
        color: rgba($white, .35);
      }

      ul {
        li {
          & > a {
            color: rgba($white, .5);

            .sidebar-icon {
              & > * {
                color: $white;
                opacity: .3;
              }
            }
          }

          ul {
            li {
              a {
                &:after {
                  background: rgba($black, .2);
                }
              }
            }
          }
        }

        li > a:hover,
        li > a.active,
        li.active > a,
        li.submenu-open > a {
          color: rgba($white, 1);
          background: rgba($black, .15);

          .sidebar-icon-indicator {
            opacity: .7;
          }

          .sidebar-icon {
            & > * {
              opacity: .6;
            }
          }
        }
      }
    }
  }
}
.app-sidebar-collapsed {
  .app-sidebar--dark:not(:hover) {
    .app-sidebar--content {
      .sidebar-navigation {
        .sidebar-header {
          &:after {
            background: rgba($white, .15);
          }
        }
      }
    }
  }
}
